<template>
  <div style="padding: 10px; min-height: 100vh; background: #f3f3f3">
    <div class="header">
      <van-row gutter="10">
        <van-col span="12" @click="onClickBack">
          <svg-icon
            :data_iconName="'back-active'"
            :className="'back_icon'"
            style="height: 25px; color: inherit"
          />
          <span style="font-size: 25px; color: #333; font-weight: bold"
            >修改密码</span
          >
        </van-col>
        <van-col span="12" class="header_action_title" @click="submit"
          >完成</van-col
        >
      </van-row>
    </div>
    <card style="margin-top: 30px; margin-bottom: 70px">
      <template #content>
        <fieldForm
          :submit="submit"
          :modal="passwords"
          :fieldData="fields"
          v-on:formUpdate="onFormUpdate"
        ></fieldForm>
      </template>
    </card>
  </div>
</template>

<script>
import fieldForm from "@/views/components/FieldForm";
import api from "@/services/apis.js";
import card from "@/views/components/Card";
import {Toast} from "vant"
export default {
  components: {
    fieldForm,
    card,
  },
  data() {
    return {
      passwords: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      fields: [
        {
          title: "旧密码",
          name: "old_password",
          type: "password",
          inline: false,
          rule: [
            {
              validator: this.oldPasswordValidate,
              message: "",
            },
          ],
        },
        {
          title: "密码",
          name: "password",
          type: "password",
          inline: false,
          rule: [
            {
              validator: this.passwordValidate,
              message: "",
            },
          ],
        },
        {
          title: "确认密码",
          name: "password_confirmation",
          type: "password",
          inline: false,
          rule: [
            {
              validator: this.passwordConfirmValidate,
              message: "两次密码输入不一致",
            },
          ],
        },
      ],
    };
  },
  methods: {
    passwordConfirmValidate(val) {
      return val === this.passwords.password || this.passwords.password === undefined;
    },
    oldPasswordValidate(val) {
      if (
        this.passwords.password == null &&
        this.passwords.password_confirmation == null
      ) {
        return val == "";
      } else {
        return val != "";
      }
    },
    passwordValidate(val) {
      if (this.passwords.old_password != "") {
        return val != "";
      }
      if (this.passwords.password_confirmation != "") {
        return val != "";
      }
    },
    submit() {
      let self = this;
      if (
        self.passwords.password != "" &&
        self.passwords.password_confirmation != "" &&
        self.passwords.old_password != ""
      ) {
        api.visitor_change_password(this.passwords).then((passwordRes) => {
          if (passwordRes.status === 200) {
            self.$router.push("/wode/edit");
          } else {
            Toast(passwordRes.message);
          }
        });
      } 
    },
    onFormUpdate(data) {
      this.passwords.old_password = data.old_password;
      this.passwords.password = data.password;
      this.passwords.password_confirmation = data.password_confirmation;
    },
    onClickBack() {
      this.$router.go(-1)
    },
  },
};
</script>

<style scoped>
.header_action_title {
  font-size: 25px;
  color: #333;
  font-weight: bold;
  text-align: right;
  line-height: 50px;
  padding-top: 0.1rem;
}

</style>